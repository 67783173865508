<template>
  <v-select
    v-bind="$attrs"
    item-title="value"
    item-value="id"
    validate-on="lazy blur"
  >
    <template #loader="{ isActive }">
      <div
        v-if="isActive"
        class="h-100 bg-blue-50 d-flex align-center justify-center cursor-not-allowed"
      >
        <Spinner class="position-absolute" size="small" />
      </div>
    </template>
    <template #no-data>
      <div class="py-2 px-4">
        {{ t('common.noData') }}
      </div>
    </template>
    <template v-if="chip" #selection="{ item, index }">
      <Chip :key="index">
        {{ item.title }}
      </Chip>
    </template>
  </v-select>
</template>

<script setup lang="ts">
import type { VSelect } from 'vuetify/components';

// sfc-compiler has problem with inheriting complex types
// from libraries, therefor we need to use following ignore statement
type SelectProps = InstanceType<typeof VSelect>['$props'];
interface Props extends /* @vue-ignore */ SelectProps {
  chip?: boolean;
}

withDefaults(defineProps<Props>(), { chip: false });

const { t } = useI18n();
</script>
